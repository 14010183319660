var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-equip", {
                  attrs: { label: "설비", type: "search", name: "equipmentCd" },
                  model: {
                    value: _vm.searchParam.equipmentCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentCd", $$v)
                    },
                    expression: "searchParam.equipmentCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "MDM_MAINTENANCE_KIND_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "equipmentCheckTypeCd",
                    label: "정비유형",
                  },
                  model: {
                    value: _vm.searchParam.equipmentCheckTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentCheckTypeCd", $$v)
                    },
                    expression: "searchParam.equipmentCheckTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.checkNoPlanItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "noPlanResultEnrollFlag",
                    label: "계획/무계획",
                  },
                  model: {
                    value: _vm.searchParam.noPlanResultEnrollFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "noPlanResultEnrollFlag", $$v)
                    },
                    expression: "searchParam.noPlanResultEnrollFlag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    stepperGrpCd: "MIM_MAIN_STAUTS_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "maintenanceStatusCd",
                    label: "진행상태",
                  },
                  model: {
                    value: _vm.searchParam.maintenanceStatusCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "maintenanceStatusCd", $$v)
                    },
                    expression: "searchParam.maintenanceStatusCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: { range: true, name: "period", label: "정비예정일" },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "설비정비 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            checkClickFlag: false,
            selection: "multiple",
            rowKey: "minEquipmentMaintenanceId",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["maintenanceStatusCd"]
                    ? [
                        _c(
                          "q-chip",
                          {
                            class:
                              props.row.maintenanceStatusCd === "MCSC000020"
                                ? "blinking"
                                : null,
                            attrs: {
                              outline: "",
                              square: "",
                              color: _vm.setTagColor(
                                props.row.maintenanceStatusCd
                              ),
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.btnClicked(props.row)
                              },
                            },
                          },
                          [
                            props.row.maintenanceStatusCd === "MCSC000020"
                              ? _c("q-avatar", {
                                  attrs: {
                                    icon: "alarm",
                                    color: "red",
                                    "text-color": "white",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setTagName(props.row.maintenanceStatusCd)
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  [
                    _c("c-btn", {
                      attrs: {
                        label: "계획등록",
                        editable: _vm.editable,
                        icon: "add",
                      },
                      on: {
                        btnClicked: function ($event) {
                          return _vm.onItemClick("A")
                        },
                      },
                    }),
                    _c("c-btn", {
                      attrs: {
                        label: "결과등록",
                        editable: _vm.editable,
                        icon: "add",
                      },
                      on: {
                        btnClicked: function ($event) {
                          return _vm.onItemClick("B")
                        },
                      },
                    }),
                    _c("c-btn", {
                      attrs: {
                        label: "무계획결과등록",
                        editable: _vm.editable,
                        icon: "add",
                      },
                      on: {
                        btnClicked: function ($event) {
                          return _vm.onItemClick("C")
                        },
                      },
                    }),
                    _c("c-btn", {
                      attrs: { label: "검색", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                    _c("c-btn", {
                      attrs: {
                        label: "삭제",
                        editable: _vm.editable,
                        icon: "delete_forever",
                      },
                      on: { btnClicked: _vm.remove },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }